/* Footer Section*/
#footer {
  background: #fff;
  padding: 80px 0;
}

#footer .container {
  max-width: 1200px;
}

#footer .more-info {
  background: #292929;
  padding: 80px 120px;
  border-radius: 20px;
  margin-bottom: 60px;
}

#footer .left-section h2 {
  font-weight: 700;
  font-size: 35px;
  line-height: 52px;
  text-transform: none;
  color: #FFFFFF;
  margin-bottom: 10px;
}

#footer .left-section p {
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF;
  max-width: 485px;
}

#footer .right-section {
  display: grid;
  justify-content: center;
}

#footer .ws-img-container,
#footer .email-img-container {
  border-radius: 10px;
  width: 220px;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px 50px;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

#footer .ws-img-container {
  background: #25D366;
}

#footer .email-img-container {
  background: #FFFFFF;
}

#footer .ws-img-container:hover,
#footer .email-img-container:hover {
  transform: scale(1.1);
}

#footer .right-section a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

#footer .ws-img-container a {
  color: #fff;
}

#footer .email-img-container a {
  color: #000;
}

#footer .right-section img {
  width: 25px;
  height: auto;
  margin-right: 10px;
}

#footer .social-media {
  margin: 20px 0;
}

#footer .social-media .social-media-section {
  padding: 0;
}

#footer .boost-traffics {
  margin-bottom: 10px;
}

#footer .boost-traffics img {
  width: 250px;
  height: auto;
}

#footer .social-media-section p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5C5C5C;
  max-width: 300px;
  margin-bottom: 30px;
}

#footer .social-media-section .social-media-icons .social-icon {
  width: 30px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
  margin-right: 7.5px;
  margin-left: 7.5px;
}

.social-icon:hover {
  transform: scale(1.2);
}

#footer .about-section h5,
#footer .more-section h5,
#footer .contact-section h5 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: none;
  color: #000;
}

#footer .social-media li {
  margin-bottom: 10px;
}

#footer .about-section a,
#footer .more-section a {
  color: #5C5C5C;
}

#footer .contact-section a {
  color: #fff;
}

#footer .about-section a,
#footer .more-section a,
#footer .contact-section a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

#footer .about-section a:hover,
#footer .more-section a:hover,
#footer .contact-section a a:hover {
  font-weight: bold;
  color: #000;
}

#footer .about-section,
#footer .more-section,
#footer .contact-section {
  text-align: center;
}

#footer .contact-section .btn-contact {
  background: #292929;
  border-radius: 5px;
  padding: 10px 25px;
  transition: transform 0.3s ease-in-out;
}

#footer .contact-section .btn-contact:hover {
  transform: scale(1.1);
}

@media (max-width: 991px) {
  #footer .more-info .left-section {
    margin-bottom: 20px;
    text-align: center;
  }

  #footer .left-section p {
    max-width: 100%;
  }

  #footer .more-info .right-section {
    display: flex;
    gap: 20px;
  }

  #footer .social-media .social-media-section {
    text-align: center;
  }

  #footer .social-media-section p {
    max-width: 100%;
  }

  #footer .about-section,
  #footer .more-section,
  #footer .contact-section {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  #footer {
    padding: 30px 0;
  }

  #footer .more-info {
    padding: 30px;
    margin-bottom: 30px;
  }

  #footer .more-info .right-section {
    gap: 10px;
  }

  #footer .more-info .right-section .row{
    display: flex;
    flex-grow: 1;
  }

  #footer .left-section h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
  }

  #footer .left-section p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  #footer .right-section {
    padding: 0;
    justify-content: flex-start;
  }

  #footer .ws-img-container,
  #footer .email-img-container {
    padding: 14px 24px;
    width: 100%;
  }

  #footer .right-section a {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    width: 100%;
  }

  #footer .right-section img {
    width: 18px;
  }

  #footer .social-media {
    margin: 0;
  }

  #footer .boost-traffics {
    text-align: center;
  }

  #footer .boost-traffics img{
    width: 175px;
  }


  #footer .social-media-section p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin-bottom: 20px;
  }

  #copyright .container{
    padding: 0 20px !important;
  }
}