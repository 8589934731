.cookie-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: end;
    z-index: 1000;
}

.cookie-popup-content {
    padding: 33px 50px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
    background: #292929;
    box-shadow: 0px -2px 2px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.cookie-popup-content p {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    max-width: 900px;
    text-align: left;
}

.policy-link {
    color: #ff6a00;
    text-decoration: none;
}

.cookie-buttons {
    display: flex;
    justify-content: space-around;
}

.cookie-buttons .btn {
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
}

.accept-btn {
    background-color: #ff6a00;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
}

.deny-btn {
    background: transparent;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: rgba(119, 119, 119, 0.466667);
}

.cookie-buttons .btn:hover {
    opacity: 0.9;
}


@media screen and (max-width: 767px) {
    .cookie-popup-content {
        display: block;
    }

    .cookie-popup-content p {
        margin-bottom: 20px;
    }

    .cookie-buttons {
        width: 100%;
    }

    .accept-btn {
        width: 75%;
    }

    .deny-btn {
        width: 25%;
    }
}

@media screen and (max-width: 575px) {
    .cookie-popup-content {
        padding: 20px;
    }

    .cookie-popup-content p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    .accept-btn {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .deny-btn {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }
}