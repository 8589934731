#policy {
    padding: 100px 0;
}

#policy .container {
    max-width: 1240px;
}

#policy h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 10px;
}

#policy p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 50px;
}

#policy .policy-section {
    margin-bottom: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#policy .policy-section .left-section {
    max-width: 350px;
    width: 100%;
}

#policy .policy-section .right-section {
    max-width: 710px;
    width: 100%;
}

#policy .policy-section h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    text-transform: none;
}

#policy .policy-section p {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    margin-bottom: 20px;
}

#policy .policy-section ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style: disc;
    margin-bottom: 20px;
}

#policy .policy-section ul li,
#policy .policy-section ul li a {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}

#policy .policy-section ul li a{
    color: #000 !important;
}

#policy .policy-section strong {
    font-weight: 600;
}

#policy .policy-section .no-bullets {
    list-style: none;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 767px) {

    #policy .policy-section {
        margin-bottom: 50px;
        display: block;
    }
}

@media screen and (max-width: 575px) {
    #policy {
        padding: 30px 0;
    }

    #policy h2 {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 10px;
    }

    #policy p {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 30px;
    }

    #policy .policy-section {
        margin-bottom: 30px;
    }

    #policy .policy-section h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    #policy .policy-section p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    #policy .policy-section ul li,
    #policy .policy-section ul li a {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #000;
    }

}