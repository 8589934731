/* Navigation */
#menu {
  padding: 15px;
  transition: all 0.8s;
}

#menu.navbar-default {
  background-color: #fff;
  border-color: rgba(231, 231, 231, 0);
}

#menu a.navbar-brand {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  width: 250px;
}

#menu a.navbar-brand img{
  width: 100%;
  height: auto;
}

#menu.navbar-default .navbar-nav .divider {
  border-left: 1px solid #ddd;
  height: 50px;
  margin: 0 10px;
  display: block;
}

#menu.navbar-default .navbar-nav .language-button-desktop{
  display: none;
}

#menu.navbar-default .navbar-nav .signin-btn {
  background: #1A237E;
  color: #fff;
  padding: 12px 30px 11px;
  border: none;
  border-radius: 10px;
  margin-top: 0;
}


#menu.navbar-default .navbar-nav>li>a {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: #000;
  padding: 8px 2px;
  border-radius: 0;
  margin: 7px 20px 0;
}

#menu.navbar-default .navbar-nav>li>a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}

#menu.navbar-default .navbar-nav .signin-btn:after {
  background: none;
}

#menu.navbar-default .navbar-nav>li>a:hover:after {
  width: 100%;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
  background-color: transparent;
}

/* .navbar-default .navbar-nav>.active>a:after,
  .navbar-default .navbar-nav>.active>a:hover:after,
  .navbar-default .navbar-nav>.active>a:focus:after {
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    width: 100% !important;
    height: 2px !important;
    background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
    content: "" !important;
    transition: width 0.2s !important;
  } */

.navbar-toggle {
  border-radius: 0;
  margin-right: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}

.navbar-default .navbar-toggle:hover>.icon-bar {
  background-color: #608dfd;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}

.section-title p {
  font-size: 18px;
}

.btn-custom {
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}

.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

.dropdown-menu{
  right: 60px;
  top: 70%;
  left: auto;
  min-width: 100px;
  padding: 0;
  margin: 0;
}

.dropdown-menu>li>a{
  padding-top: 5px;
  padding-bottom: 5px;

}

.dropdown-menu .active{
  background: #FF6F00;
  color: #fff;
}


@media (max-width: 1199px) {
  #menu .container {
    max-width: 100%;
  }

  #menu.navbar-default .navbar-header .language-button {
    border: none;
    max-height: 34px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-right: 5px;
    display: none;
  }

  #menu.navbar-default .navbar-header .language-button img {
    width: 24px;
    height: 24px;
  }

  .navbar-default .navbar-collapse .dropdown {
    display: none;
  }

  .navbar-default .navbar-toggle {
    border: none;
  }

  .navbar-toggle .icon-bar {
    height: 3px;
    border-radius: 10px;
  }

  #menu.navbar-default .navbar-nav .divider {
    display: none;
  }

  #menu.navbar-default {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  #menu.navbar-default .navbar-nav .signin-btn {
    background: transparent;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #000;
    padding: 8px 2px;
    border-radius: 0;
    margin: 7px 20px 0;
    text-align: left;
  }

}

@media screen and (max-width: 575px) {
  #menu.navbar-default .navbar-nav>li>a,
  #menu.navbar-default .navbar-nav .signin-btn{
    font-size: 14px;
    line-height: 21px;
  }

  #menu a.navbar-brand {
    display: flex;
    align-items: center;
    width: 175px;
  }

  .navbar-brand>img {
    height: 30px;
  }
}