#buttonGroup {
    padding-top: 160px;
}

#buttonGroup .container {
    max-width: 1240px;
}

.button-group {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

#buttonGroup .button {
    padding: 12px 20px;
    border: none;
    border-radius: 200px;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: 27px;
    text-transform: uppercase;
    background-color: #f5f5f5;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 100%;
    max-width: 350px;
}

#buttonGroup .button.active {
    background-color: #f57c00;
    color: #fff;
}

#buttonGroup .button:hover {
    opacity: 0.9;
}

@media (max-width: 991px) {
    .button-group {
        display: block;
        margin: 0 10px;
    }

    #buttonGroup .button {
        max-width: 100%;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 575px) {
    #buttonGroup{
        padding-top: 117px;
    }

    #buttonGroup .button {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
    }
}