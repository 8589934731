
/* Copyright */
#copyright {
    background: #292929;
    padding: 15px 0;
  }
  
  #copyright .container {
    padding-left: 90px;
    padding-right: 90px;
  }
  
  #copyright .footer-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #copyright .footer-text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0;
  }
  